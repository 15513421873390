import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66bf15d6 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _2f02f63f = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _2c48ea70 = () => interopDefault(import('../pages/groups/index.vue' /* webpackChunkName: "pages/groups/index" */))
const _8c7a7bc0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2ea23bb4 = () => interopDefault(import('../pages/materials/index.vue' /* webpackChunkName: "pages/materials/index" */))
const _3dc95e64 = () => interopDefault(import('../pages/objects/index.vue' /* webpackChunkName: "pages/objects/index" */))
const _b712daf0 = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _719e29a8 = () => interopDefault(import('../pages/warehouses/index.vue' /* webpackChunkName: "pages/warehouses/index" */))
const _b7cc3520 = () => interopDefault(import('../pages/accounting/import-tasks-prices.vue' /* webpackChunkName: "pages/accounting/import-tasks-prices" */))
const _2e0313d2 = () => interopDefault(import('../pages/accounting/invoices.vue' /* webpackChunkName: "pages/accounting/invoices" */))
const _bc02276a = () => interopDefault(import('../pages/accounting/materials.vue' /* webpackChunkName: "pages/accounting/materials" */))
const _2feefd56 = () => interopDefault(import('../pages/debug/icons.vue' /* webpackChunkName: "pages/debug/icons" */))
const _259b7d1f = () => interopDefault(import('../pages/debug/themes.vue' /* webpackChunkName: "pages/debug/themes" */))
const _affb7f10 = () => interopDefault(import('../pages/materials/categories.vue' /* webpackChunkName: "pages/materials/categories" */))
const _a2238930 = () => interopDefault(import('../pages/materials/print_codes.vue' /* webpackChunkName: "pages/materials/print_codes" */))
const _17d6211a = () => interopDefault(import('../pages/paid_incoming_supplier_invoices/import.vue' /* webpackChunkName: "pages/paid_incoming_supplier_invoices/import" */))
const _5a5cadd9 = () => interopDefault(import('../pages/reports/accounting_materials_transactions.vue' /* webpackChunkName: "pages/reports/accounting_materials_transactions" */))
const _3cfe0928 = () => interopDefault(import('../pages/reports/departures.vue' /* webpackChunkName: "pages/reports/departures" */))
const _14e8871c = () => interopDefault(import('../pages/reports/departures_price.vue' /* webpackChunkName: "pages/reports/departures_price" */))
const _6550822e = () => interopDefault(import('../pages/reports/employee-stats.vue' /* webpackChunkName: "pages/reports/employee-stats" */))
const _5ec7f956 = () => interopDefault(import('../pages/reports/my.vue' /* webpackChunkName: "pages/reports/my" */))
const _13dd3106 = () => interopDefault(import('../pages/reports/stats.vue' /* webpackChunkName: "pages/reports/stats" */))
const _952770b4 = () => interopDefault(import('../pages/reports/warehouses_materials.vue' /* webpackChunkName: "pages/reports/warehouses_materials" */))
const _626bbb4b = () => interopDefault(import('../pages/reports/warehouses_transactions.vue' /* webpackChunkName: "pages/reports/warehouses_transactions" */))
const _4483e2e0 = () => interopDefault(import('../pages/reports/workload.vue' /* webpackChunkName: "pages/reports/workload" */))
const _0b3cb830 = () => interopDefault(import('../pages/tasks/activity_codes.vue' /* webpackChunkName: "pages/tasks/activity_codes" */))
const _2cc37d56 = () => interopDefault(import('../pages/tasks/categories.vue' /* webpackChunkName: "pages/tasks/categories" */))
const _9b4a1aa4 = () => interopDefault(import('../pages/warehouses/me.vue' /* webpackChunkName: "pages/warehouses/me" */))
const _7a8f9583 = () => interopDefault(import('../pages/sync/prengi/categories.vue' /* webpackChunkName: "pages/sync/prengi/categories" */))
const _39856586 = () => interopDefault(import('../pages/sync/prengi/objects.vue' /* webpackChunkName: "pages/sync/prengi/objects" */))
const _396bbd47 = () => interopDefault(import('../pages/sync/prengi/statuses.vue' /* webpackChunkName: "pages/sync/prengi/statuses" */))
const _69eaec5e = () => interopDefault(import('../pages/sync/prengi/workers.vue' /* webpackChunkName: "pages/sync/prengi/workers" */))
const _5c1a24c5 = () => interopDefault(import('../pages/tasks/departures/is_billed.vue' /* webpackChunkName: "pages/tasks/departures/is_billed" */))
const _1fc9c1ee = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _26e8b8bf = () => interopDefault(import('../pages/companies/_company_id.vue' /* webpackChunkName: "pages/companies/_company_id" */))
const _4e5beda0 = () => interopDefault(import('../pages/employees/_employee_id.vue' /* webpackChunkName: "pages/employees/_employee_id" */))
const _0e7402d8 = () => interopDefault(import('../pages/groups/_group_id.vue' /* webpackChunkName: "pages/groups/_group_id" */))
const _25fb32d0 = () => interopDefault(import('../pages/materials/_material_id.vue' /* webpackChunkName: "pages/materials/_material_id" */))
const _9cfc7d60 = () => interopDefault(import('../pages/objects/_object_id.vue' /* webpackChunkName: "pages/objects/_object_id" */))
const _17c078e0 = () => interopDefault(import('../pages/tasks/_task_id.vue' /* webpackChunkName: "pages/tasks/_task_id" */))
const _5ba337b0 = () => interopDefault(import('../pages/warehouses/_warehouse_uuid.vue' /* webpackChunkName: "pages/warehouses/_warehouse_uuid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/companies",
    component: _66bf15d6,
    name: "companies"
  }, {
    path: "/employees",
    component: _2f02f63f,
    name: "employees"
  }, {
    path: "/groups",
    component: _2c48ea70,
    name: "groups"
  }, {
    path: "/login",
    component: _8c7a7bc0,
    name: "login"
  }, {
    path: "/materials",
    component: _2ea23bb4,
    name: "materials"
  }, {
    path: "/objects",
    component: _3dc95e64,
    name: "objects"
  }, {
    path: "/tasks",
    component: _b712daf0,
    name: "tasks"
  }, {
    path: "/warehouses",
    component: _719e29a8,
    name: "warehouses"
  }, {
    path: "/accounting/import-tasks-prices",
    component: _b7cc3520,
    name: "accounting-import-tasks-prices"
  }, {
    path: "/accounting/invoices",
    component: _2e0313d2,
    name: "accounting-invoices"
  }, {
    path: "/accounting/materials",
    component: _bc02276a,
    name: "accounting-materials"
  }, {
    path: "/debug/icons",
    component: _2feefd56,
    name: "debug-icons"
  }, {
    path: "/debug/themes",
    component: _259b7d1f,
    name: "debug-themes"
  }, {
    path: "/materials/categories",
    component: _affb7f10,
    name: "materials-categories"
  }, {
    path: "/materials/print_codes",
    component: _a2238930,
    name: "materials-print_codes"
  }, {
    path: "/paid_incoming_supplier_invoices/import",
    component: _17d6211a,
    name: "paid_incoming_supplier_invoices-import"
  }, {
    path: "/reports/accounting_materials_transactions",
    component: _5a5cadd9,
    name: "reports-accounting_materials_transactions"
  }, {
    path: "/reports/departures",
    component: _3cfe0928,
    name: "reports-departures"
  }, {
    path: "/reports/departures_price",
    component: _14e8871c,
    name: "reports-departures_price"
  }, {
    path: "/reports/employee-stats",
    component: _6550822e,
    name: "reports-employee-stats"
  }, {
    path: "/reports/my",
    component: _5ec7f956,
    name: "reports-my"
  }, {
    path: "/reports/stats",
    component: _13dd3106,
    name: "reports-stats"
  }, {
    path: "/reports/warehouses_materials",
    component: _952770b4,
    name: "reports-warehouses_materials"
  }, {
    path: "/reports/warehouses_transactions",
    component: _626bbb4b,
    name: "reports-warehouses_transactions"
  }, {
    path: "/reports/workload",
    component: _4483e2e0,
    name: "reports-workload"
  }, {
    path: "/tasks/activity_codes",
    component: _0b3cb830,
    name: "tasks-activity_codes"
  }, {
    path: "/tasks/categories",
    component: _2cc37d56,
    name: "tasks-categories"
  }, {
    path: "/warehouses/me",
    component: _9b4a1aa4,
    name: "warehouses-me"
  }, {
    path: "/sync/prengi/categories",
    component: _7a8f9583,
    name: "sync-prengi-categories"
  }, {
    path: "/sync/prengi/objects",
    component: _39856586,
    name: "sync-prengi-objects"
  }, {
    path: "/sync/prengi/statuses",
    component: _396bbd47,
    name: "sync-prengi-statuses"
  }, {
    path: "/sync/prengi/workers",
    component: _69eaec5e,
    name: "sync-prengi-workers"
  }, {
    path: "/tasks/departures/is_billed",
    component: _5c1a24c5,
    name: "tasks-departures-is_billed"
  }, {
    path: "/",
    component: _1fc9c1ee,
    name: "index"
  }, {
    path: "/companies/:company_id",
    component: _26e8b8bf,
    name: "companies-company_id"
  }, {
    path: "/employees/:employee_id",
    component: _4e5beda0,
    name: "employees-employee_id"
  }, {
    path: "/groups/:group_id",
    component: _0e7402d8,
    name: "groups-group_id"
  }, {
    path: "/materials/:material_id",
    component: _25fb32d0,
    name: "materials-material_id"
  }, {
    path: "/objects/:object_id",
    component: _9cfc7d60,
    name: "objects-object_id"
  }, {
    path: "/tasks/:task_id",
    component: _17c078e0,
    name: "tasks-task_id"
  }, {
    path: "/warehouses/:warehouse_uuid",
    component: _5ba337b0,
    name: "warehouses-warehouse_uuid"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
